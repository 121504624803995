import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/animatedPage";
import api from "../../utils/server";
import { useContext, useState } from "react";
import { OverlayContext } from "../../components/overlayContext";

const CancelSuccess = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate('/');
  }
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-lg overflow-hidden">
        <div className="px-8 py-6">
          <div className="flex justify-center mb-6">
            <svg className="w-16 h-16 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">Subscription Cancelled</h2>
          <p className="text-center text-gray-600 mb-6">
            Your subscription has been successfully cancelled. We're sorry to see you go!
          </p>
          <p className="text-center text-gray-600 mb-8">
            We hope to see you back soon. Remember, you can resubscribe at any time to regain access to our premium features.
          </p>
        </div>
        <div className="px-8 py-6 bg-white">
          <button 
            className="w-full bg-blue-900 hover:bg-blue-800 text-white font-bold py-3 px-4 rounded-full transition duration-300"
            onClick={back}
          >
            Back to Homepage
          </button>
        </div>
      </div>
    </div>
  )
}

const CancelConfirmDialog = ({onSubscriptionCancel}:{onSubscriptionCancel: () => void}) => {
  const navigate = useNavigate();
  const back = () => {
    navigate('/');
  }
  return (
    <AnimatedPage className="fixed inset-0 flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-lg overflow-hidden">
        <div className="px-8 py-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">Cancel Subscription</h2>
          <p className="text-center text-gray-600 mb-6">
            Are you sure you want to cancel your subscription? You'll lose access to the following benefits:
          </p>
          <ul className="mt-6 mb-8 space-y-2 text-gray-700">
            <li className="flex items-center justify-center">
              <svg className="w-5 h-5 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
              Unlimited access to website
            </li>
            <li className="flex items-center justify-center">
              <svg className="w-5 h-5 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
              Ad-free experience
            </li>
          </ul>
        </div>
        <div className="px-8 py-6 bg-gray-50 space-y-4">
          <button 
            className="w-full bg-blue-900 hover:bg-blue-800 text-white font-bold py-3 px-4 rounded-full transition duration-300"
            onClick={back}
            >
            Keep My Subscription
          </button>
          <button 
            className="w-full bg-white hover:bg-gray-100 text-blue-900 font-bold py-3 px-4 rounded-full transition duration-300 border border-blue-900"
            onClick={onSubscriptionCancel}
            >
            Cancel Subscription
          </button>
        </div>
      </div>
    </AnimatedPage>
  )
}

export default function CancelSubscriptionConfirmation(){
  const [canceled, setCanceled] = useState(false);
  const {loading, setLoading} = useContext(OverlayContext);

    const onSubscriptionCancel = () => {
        setLoading(true);
        api<{success: boolean}>('/subscription/cancel', {method: 'POST'}, true, (data) => {
            console.log(data);
            setCanceled(true);
            setLoading(false);
        }, (e) => {
            console.log(e);
            setLoading(false);
        })
    }

    return canceled ? <CancelSuccess/> : <CancelConfirmDialog onSubscriptionCancel={onSubscriptionCancel}/>;
}