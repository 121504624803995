const host = process.env.REACT_APP_API_HOST! + '/api';

async function api<T>(path: string, request: RequestInit={}, displayError: boolean=true, onSuccess?: (data: T)=>void, onError?: (err: Response)=>void){
    const jwt = (localStorage.getItem('jwt'));
    const response = await fetch(host + path, {
        ...request,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': (jwt ? `Bearer ${jwt}` : '')
        }
    });
    const data = await response.json() as  (T & {error?: string});

    if(!response.ok){
        if(onError) onError(response);
        if(displayError){
            if(data.error) 
                alert("Server error: " + data.error);
            else 
                alert("Server error occurred");
        }
        return response;
    }
        
    if(onSuccess){
        onSuccess(data);
    } 

    return data;
}

export default api;