import {FaceMesh} from '@mediapipe/face_mesh';
import * as FM from '@mediapipe/face_mesh';
import * as cam from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';
import { HTMLProps, useEffect, useRef, useState } from 'react';


const lipsUpperOuter = [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291];
const lipsLowerOuter = [61, 146, 91, 181, 84, 17, 314, 405, 321, 375, 291];
const lipsUpperInner = [78, 191, 80, 81, 82, 13, 312, 311, 310, 415, 308];
const lipsLowerInner = [78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308];
const MOUTH_LANDMARKS_v1 = [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291, 375, 321, 405, 314, 17, 84, 181, 91, 146, 61];
const MOUTH_LANDMARKS = [...lipsLowerInner, ...lipsUpperInner];
let MOUTH_COUNT = MOUTH_LANDMARKS.length;

const left = 61;
const right = 291;
const top = 0;
const bottom = 17;

const dist = (a: FM.NormalizedLandmark, b: FM.NormalizedLandmark): number => {
    return Math.sqrt(
        Math.pow(a.x - b.x, 2) +
        Math.pow(a.y - b.y, 2) + 
        Math.pow(a.z - b.z, 2) 
    ); 
};

const countMiddle = (mouth: FM.NormalizedLandmarkList): FM.NormalizedLandmark => {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    mouth.map(a => {
        sumX += a.x;
        sumY += a.y;
        sumZ += a.z;
    });

    return {
        x: sumX / MOUTH_COUNT,
        y: sumY / MOUTH_COUNT,
        z: sumZ / MOUTH_COUNT,
    };
};

const averageDistance = (mouth: FM.NormalizedLandmarkList, middle: FM.NormalizedLandmark): number => {
    let sum = 0;
    mouth.map(landmark => {
        sum += dist(middle, landmark);
    });
    return (sum / MOUTH_COUNT);
};

const longestDistance = (mouth: FM.NormalizedLandmarkList) => {
    var longest = 0;
    mouth.map((one) => {
        mouth.map((two) => {
            longest = Math.max(longest, dist(one, two));
        });
    });
    return longest;
};

const calculateLandmarks = (arr: FM.NormalizedLandmarkList): [number, number] => {
    const mouth = arr.filter((a, i) => MOUTH_LANDMARKS.includes(i));
    const middle = countMiddle(mouth);
    const avg = averageDistance(mouth, middle);
    const longest = longestDistance(mouth);
    return [avg, longest];
};

const findRatio = (arr: FM.NormalizedLandmarkList): [number, number, number] => {
    const vertical = dist(arr[top], arr[bottom]);
    const horizontal = dist(arr[left], arr[right]);
    return [horizontal, vertical, vertical/horizontal];
};

const round = (a: number, r: number=3): number=>{
    const tens = Math.pow(10, r);
    return Math.round(a * tens) / tens;
}

interface Props extends HTMLProps<HTMLDivElement>{
    onOpen?: () => void,
    onClose?: () => void,
    onEvent?: (open: boolean) => void,
    onFaceLoad?: () => void,
};

const FaceMeshPage = ({onEvent, onOpen, onClose, onFaceLoad, ...props}: Props) => {
    const webcam = useRef<Webcam>(null);
    // const canvas = useRef<HTMLCanvasElement>(null);
    // const [camera, setCamera] = useState<any>(null);
    // const [calcs, setCalcs] = useState<any>({});
    const [mouthOpen, setMouthOpen] = useState<boolean>(false);

    const onResults: FM.ResultsListener = (results) => {
        // const canvasCtx = canvas.current?.getContext('2d');
        // canvasCtx?.save();

        // canvasCtx?.clearRect(0, 0, canvas.current?.width || 0, canvas.current?.height || 0);
        // canvasCtx?.drawImage(results.image, 0, 0, canvas.current?.width || 0, canvas.current?.height || 0);

        if(results.multiFaceLandmarks){
            results.multiFaceLandmarks.map(landmarks => {
                // const [average, longest] = calculateLandmarks(landmarks);
                const [horizontal, vertical, ratio] = findRatio(landmarks);

                // setCalcs({
                //     average: round(average, 3),
                //     longest: round(longest, 2),
                //     pointer: round(average/longest),
                //     horizontal: round(horizontal),
                //     vertical: round(vertical),
                //     ratio: round(ratio),
                // });

                setMouthOpen(ratio > 0.70);

                // (window as any).drawConnectors(canvasCtx, landmarks, FM.FACEMESH_LIPS, {
                //     color: '#fcba03',
                // });

                // (window as any).drawConnectors(canvasCtx, landmarks, FM.FACEMESH_TESSELATION, {
                //     color: '#FFF',
                //     lineWidth: 0,
                // });
            });
        }
    };

    const setUp = () => {
        const faceMesh = new FaceMesh({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });

        faceMesh.setOptions({
            maxNumFaces: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
        });

        faceMesh.onResults(onResults);

        if(!webcam.current || !webcam.current.video) return;
        
        const newCamera = new cam.Camera(webcam.current.video, {
            onFrame: async () => {
                if(webcam.current?.video)
                    await faceMesh.send({image: webcam.current?.video})   
            },
            width: 640,
            height: 480,
        });
        newCamera.start();
        if(onFaceLoad) onFaceLoad();
        // setCamera(newCamera);
    };

    useEffect(() => {
        if(webcam.current)
            setUp();
    }, [webcam.current]);

    useEffect(() => {
        if(onEvent) onEvent(mouthOpen);
        if(mouthOpen && onOpen) onOpen();
        if(!mouthOpen && onClose) onClose();
        console.log(mouthOpen);
    }, [mouthOpen]);

    useEffect(() => {
        console.log('rendering camera!');
    }, []);



    return <div style={{width: 640, height: 480, position: 'relative'}} {...props}>
        <Webcam 
            className='c' 
            ref={webcam}
            onUserMedia={() => console.log('loaded user media')}
            onUserMediaError={(error) => {console.log('error occurred'); console.log(error)}}
        />
        <div style={{width: 640, height: 480, position: 'absolute', zIndex: 100, backgroundColor: '#333333', top: 0}}/>
        {/* <canvas className='center-window' ref={canvas} width={640} height={480}/> */}
        {/* <span style={{whiteSpace: 'break-spaces'}}>{JSON.stringify(calcs, null, 2)}</span>
        <span style={mouthOpen ? {color: 'green', display: 'block'} : {display: 'block'}}>
            {mouthOpen ? 'Open' : 'Close'}
        </span> */}
    </div>;
};

export default FaceMeshPage;