import React, { HTMLProps, useEffect, useRef, useState } from "react";
import YouTube, * as YT from "react-youtube";
import FaceMeshPage from "./facemesh";

interface Props extends HTMLProps<HTMLDivElement> {
    videoId: string;
}

const YoutubeEmbed = ({ videoId, ...props }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<any>(null);
    const [faceReady, setFaceReady] = useState<boolean>(false);

    const opts: YT.YouTubeProps["opts"] = {
        height: `${window.innerHeight < window.innerWidth 
          ? window.innerHeight * 0.7 
          : window.innerWidth * 0.563 }px`,
        width: 
        `${window.innerHeight < window.innerWidth
          ? window.innerHeight * 1.25
          : window.innerWidth}px`,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const onReady = (e: any) => {
        console.log("ready!!!");
        playerRef.current = e.target;
        if(faceReady) play();
    };

    const play = () => {
      if(playerRef.current)
        playerRef.current?.playVideo();
    };

    const stop = () => {
      if(playerRef.current)
        playerRef.current?.pauseVideo();
    };

    useEffect(() => {
      play();
    }, [faceReady]);

    const toggle = () => {
        if(playerRef.current.getPlayerState() != 1)
          play();
        else
          stop();
    };

    return Boolean(videoId.length) ? (
        <div {...props} ref={containerRef}>
            <YouTube videoId={videoId} opts={opts} onReady={onReady} />
            <div className="absolute top-0 -z-10">
              <FaceMeshPage onOpen={stop} onClose={play} onFaceLoad={() => setFaceReady(true)}/>
            </div> 
        </div>
    ) : (
        <div {...props} />
    );
};

export default YoutubeEmbed;
