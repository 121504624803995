import { Route, Routes, useLocation } from "react-router-dom";
import FaceApi from "./pages/faceapi";
import FaceMeshPage from "./pages/facemesh";
import YouTubeEmbed from "./pages/youtube";
import Home from "./pages/home";
import FaceMeshOwn from "./pages/facemeshown";
import { useRef } from "react";
import AccountHome from "./pages/account/home";
import Login from "./pages/account/login";
import Subscription from "./pages/account/subscription";
import Status from "./pages/status";
import CancelSubscriptionConfirmation from "./pages/account/cancelSubscription";

const Routing = () => {
    const location = useLocation();

    return (
        <Routes location={location} key={location.key}>
            <Route
                path="/"
                element={<Home/>}
            />
            <Route
                path="/acc"
                element={<AccountHome/>}
            />
            <Route
                path="/acc/login"
                element={<Login/>}
            />
            <Route
                path="/acc/sub"
                element={<Subscription/>}
            />
            <Route
                path="/acc/sub/success"
                element={<Status status="success"/>}
            />
            <Route
                path="/acc/sub/canceled"
                element={<Status status='cancelled'/>}
            />
            <Route
                path="/acc/sub/cancel"
                element={<CancelSubscriptionConfirmation/>}
            />
            {/* <Route
                path="faceapi"
                element={<FaceApi/>}
            />
            <Route
                path="facemesh"
                element={<FaceMeshPage/>}
            />
            <Route
                path="f"
                element={<FaceMeshOwn/>}
            /> */}

            {/* <Route
                path="yt"
                element={<YouTubeEmbed/>}
            /> */}
        </Routes>
    )
};

export default Routing;