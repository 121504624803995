import React, { useContext, useEffect, useState } from 'react'
import api from '../../utils/server'
import { useNavigate } from 'react-router-dom';
import { ServerResponseMe } from '../../@types/user';
import AnimatedPage from '../../components/animatedPage';
import { OverlayContext } from '../../components/overlayContext';

export default function Component() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState<string>('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const {loading, setLoading} = useContext(OverlayContext);
  const navigate = useNavigate();

  useEffect(() => {
    api<ServerResponseMe>('/user/me', {}, false, (data) => {
        if(data.email) navigate('/');
    });
  }, []);

  const handleSendOtp = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true);
    console.log('Sending OTP to', email);

    api<any>('/auth/send-otp', {body: JSON.stringify({email}), method: 'POST'}, true, (data) => {
        console.log(data);
        setIsOtpSent(true);
        setLoading(false);
    }, (error) => {
        setLoading(false);
    });
  }

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault()
    console.log('Logging in with OTP', otp);
    setLoading(true);

    api<any>('/auth/login', {body: JSON.stringify({email, code: otp}), method: 'POST'}, false, 
        (data) => {
            localStorage.setItem('jwt', data.jwt);
            console.log(data);
            setLoading(false);
            if(data.access === 'active'){
                navigate('/');
            }
            else{
                navigate('/acc/sub');
            }
        },
        async (error) => {
            setLoading(false);
            const data = await error.json();
            if(data.error) setMessage(data.error);
            else setMessage("Server error occurred, cannot log in");
        } 
    );
  }

  const handleBack = () => {
    setIsOtpSent(false)
    setOtp('')
  }

  return (
    <AnimatedPage className="flex justify-center items-center min-h-screen bg-gray-200">
      <div className="w-[350px] bg-gray-100 rounded-3xl shadow-lg p-8">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-2">Login</h2>
        <p className="text-center text-gray-600 mb-6">
          {message ? message : 
          isOtpSent ? "Enter the code sent to your email" : "Enter your email to receive an code"}
        </p>
        <form onSubmit={isOtpSent ? handleLogin : handleSendOtp} className="space-y-4">
          {!isOtpSent ? (
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 rounded-xl border-2 border-gray-300 focus:border-blue-900 focus:outline-none"
            />
          ) : (
            <>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full p-3 rounded-xl border-2 border-gray-300 focus:border-blue-900 focus:outline-none"
              />
              <button
                type="button"
                onClick={handleBack}
                className="w-full p-3 rounded-xl text-blue-900 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50 flex items-center justify-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                </svg>
                Back to Email
              </button>
            </>
          )}
          <button
            type="submit"
            className="w-full p-3 rounded-xl bg-blue-900 text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50"
          >
            {isOtpSent ? "Login" : "Send Code"}
          </button>
        </form>
      </div>
    </AnimatedPage>
  )
}