import React from 'react'
import { XIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

interface PaymentStatusProps {
  status: 'success' | 'cancelled'
}

export default function Status({ 
  status = 'success', 
}: PaymentStatusProps) {
    const navigate = useNavigate();

    const forceClose = () => {
        navigate('/');
    }

    const onClose = () => {
      if(status === 'success') {
          navigate('/');
      }
      else{
        navigate('/acc/sub');
      }
    }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white shadow-lg rounded-3xl p-6 relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          aria-label="Close"
        >
          <XIcon className="h-4 w-4" />
        </button>
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          {status == 'success' ? 'Thank You for joining! 🎉' : 'Maybe try again?'}
        </h2>
        <div className="text-center mb-8">
          {status === 'success' ? (
            <p className="text-lg font-medium text-green-600">
              Your payment was successful!
            </p>
          ) : (
            <p className="text-lg font-medium text-red-600">
              Your payment was cancelled.
            </p>
          )}
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {status === 'success' ? 'Let\'s start watching!' : 'Try Again?'}
          </button>
          {status !== 'success' && <button
            onClick={forceClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Close
          </button>
    }
        </div>
      </div>
    </div>
  )
}