import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Tv } from 'lucide-react'

export default function TVLoadingOverlay() {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-transparent">
      <div className="text-center space-y-4">
        <motion.div
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeOut",
            delay: 1
          }}
          className="relative w-20 h-20 mx-auto"
        >
          <Tv className="w-full h-full text-blue-800" />
          <div
            className="absolute inset-0 flex items-center justify-center"
          >
            <div className="w-3 h-3 bg-blue-800 rounded-full" />
          </div>
        </motion.div>
      </div>
    </div>
  )
}