import { MouseEventHandler, useEffect, useState } from "react";
import YoutubeEmbed from "./youtube";
import { useNavigate } from "react-router-dom";
import { ServerResponseMe, User } from "../@types/user";
import api from "../utils/server";
import AccountManagement from './account/manage'

const testYoutubeVideoIds = [
    'Bm5iA4Zupek',
    'a-rqu-hjobc',
    'Kbj2Zss-5GY'
];

function getYouTubeVideoId(url: string): string | null {
    // Check for regular YouTube URLs
    const regExp = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*[?&]v=([^&]+)/;
    const match = url.match(regExp);
    
    if (match && match[1]) {
        return match[1];
    }

    // Check for shortened YouTube URLs (e.g. youtu.be/VIDEO_ID)
    const shortRegExp = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?&]+)/;
    const shortMatch = url.match(shortRegExp);
    
    if (shortMatch && shortMatch[1]) {
        return shortMatch[1];
    }

    // If no match is found, return null
    return null;
}

const grayColor = '#333333';

const Home = () => {
    const [text, setText] = useState<string>("");
    const [video, setVideo] = useState<string>("");
    const [account, setAccount] = useState<User | null | undefined>(undefined);
    const [accountModal, setAccountModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const onLogout = () => {
        localStorage.removeItem('jwt');
        setAccount(null);
        setAccountModal(false);
    }

    const onAccount = (data: ServerResponseMe) => {
        console.log(data);
        setAccount(data);
    }

    const onError = (e: Response) => {
        console.log(e);
        if(e.status === 401) setAccount(null);
    };

    useEffect(() => {
        api<ServerResponseMe>('/user/me', {}, false, onAccount, onError);
    }, []);

    const startVideo = (videoId: string) => {
        if(account === undefined) return;
        if(account === null){
            return navigate('/acc/login');
        }
        else if(account?.access !== 'active'){
            return navigate('/acc/sub');
        }
        else{
            setVideo(videoId);
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        console.log('pasted!');
        const id = getYouTubeVideoId(e.nativeEvent.clipboardData?.getData('text') || '');
        if(id) startVideo(id);
    }

    const home = () => {
        setVideo("");
    };  

    const submit = () => {
        if(!text.length) return;
        const id = getYouTubeVideoId(text);
        if(id) startVideo(id);
    };

    const windowClick: MouseEventHandler<HTMLDivElement> = (e) => {
        setAccountModal(false);
    }

    const profileClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        if(account)
            setAccountModal(true);
        else
            navigate('/acc/login');
    }

    const testFree = () => {
        if(text.length) return;
        const id = testYoutubeVideoIds[Math.floor(Math.random() * testYoutubeVideoIds.length)];
        setVideo(id);
    };

    useEffect(() => {
        document.body.style.backgroundColor = (video ? grayColor : 'rgb(243,244,246)');
    }, [video]);

    return (
        <div className="page-root center overflow-hidden bg-gray-100" style={video ? {backgroundColor: grayColor} : {}} onClick={windowClick}>
            <form action="#" onSubmit={submit} className="flex center flex-col">
                <div className="w-full center logo-box cursor-pointer" onClick={home}>
                    <span className="logo-font">
                        {`MouthYT`}
                    </span>
                </div>
                <YoutubeEmbed 
                    videoId={video}
                    className="bg-black flex center" 
                    style={{
                        width: (video ? '125vh': '100%'), 
                        height: (video ? '70vh': 1),
                        maxWidth: '100vw',
                        maxHeight: '56.3vw',
                        marginBottom: '1rem',
                        overflow: 'hidden'
                    }}
                />
                <div className="min-w-full relative">
                    <input 
                        type="text" 
                        className={`main-input shadow w-full text-center ${video ? 'playing' : ''}`}
                        spellCheck={false} 
                        onChange={handleChange}
                        onPaste={handlePaste}
                    />
                    <div 
                        className={`
                            absolute right-0 top-0 test-button h-full flex 
                            ${account?.access === 'active' ? 'hidden' : ''}
                            ${video ? 'playing' : ''} 
                            ${!text.length ? 'cursor-pointer' : 'inactive'}
                        `}
                        onClick={testFree}
                    >
                        <span className="account-button flex-1">{`Try`}</span>
                    </div>
                </div>
            </form>
            <div className="fixed top-0 right-0 px-8 py-3 flex cursor-pointer transition" onClick={profileClick}>
                <span className={`py-3 px-8 account-button ${video ? 'playing' : ''}`}>{`${account?.email || 'Log in'}`}</span>
            </div>
            <AccountManagement 
                visible={accountModal}
                subscriptionStatus={account?.access || 'inactive'} 
                email={account?.email || ''} 
                onClose={() => setAccountModal(false)} 
                onLogout={onLogout}
            />
        </div>
    )
}; 

export default Home;