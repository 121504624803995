import {loadStripe, Stripe} from '@stripe/stripe-js';
import { useContext, useEffect, useState } from 'react';
import api from '../../utils/server';
import { useNavigate } from 'react-router-dom';
import AnimatedPage from '../../components/animatedPage';
import { OverlayContext } from '../../components/overlayContext';


const Old = () => {   
    const [subscription, setSubscription] = useState<string | null>(null);

    useEffect(() => {
        api<{status: string}>('/subscription', {}, false, (data) => {
            setSubscription(data.status);
        }, (err) => {
            alert("Could not get subscription status, please try again later");
            console.log(err);
        });
    }, []);

    const purchase = async () => {
        api<{sessionId: string}>('/stripe/create-checkout-session', {method: 'POST'}, false, async (data) => {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
            const result = await stripe?.redirectToCheckout({
                sessionId: data.sessionId
            });
            console.log(result);
        }, 
        (err) => {
            alert("Could not create checkout, please try again later");
            console.log(err);
        })
    };

    const cancel = () => {
        api('/subscription/cancel', {method: 'POST'}, false, () => {
            setSubscription('inactive');
        }, (err) => {
            alert("Could not cancel subscription, please try again later");
            console.log(err);
        });
    }

    return (
        <div>
            <h1>{`Subscription: ${subscription}`}</h1>
            {(subscription == 'inactive') ?
            <button onClick={purchase}>
                Purchase
            </button>
            :
            <button onClick={purchase}>
                Cancel
            </button>
            }

        </div>
    )
};

function Subscription() {
    const navigate = useNavigate();
    const { loading, setLoading } = useContext(OverlayContext);

    const purchase = async () => {
        if(loading) return;
        setLoading(true);

        api<{ sessionId: string }>(
            "/stripe/create-checkout-session",
            { method: "POST" },
            false,
            async (data) => {
                const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
                const result = await stripe?.redirectToCheckout({
                    sessionId: data.sessionId,
                });
                setLoading(false);
            },
            (err) => {
                alert("Could not create checkout, please try again later");
                console.log(err);
                setLoading(false);
            },
        );
    };

    useEffect(() => {
        api<{status: string}>('/subscription/', {}, false, (data) => {
            if(data.status === 'active') navigate('/');
        }, (err) => {
            alert("Could not get subscription status, please try again later");
            console.log(err);
        });
    }, []);

    return (
        <AnimatedPage className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded-3xl shadow-lg overflow-hidden">
                <div className="px-8 py-6">
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">Unlock Full Access</h2>
                    <p className="text-center text-gray-600 mb-6">To continue enjoying our premium content and features, please subscribe to our service.</p>
                    <div className="text-center">
                        <span className="text-4xl font-bold text-blue-900">20 zł</span>
                        <span className="text-xl text-gray-500">/month</span>
                    </div>
                    <ul className="mt-6 mb-8 space-y-2 text-gray-700">
                        <li className="flex items-center justify-center">
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            Unlimited access to website
                        </li>
                        <li className="flex items-center justify-center">
                            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            Ad-free experience
                        </li>
                    </ul>
                </div>
                <div className="px-8 py-6 bg-white">
                    <button onClick={purchase} className="w-full bg-blue-900 hover:bg-blue-800 text-white font-bold py-3 px-4 rounded-full transition duration-300">Subscribe Now</button>
                </div>
            </div>
        </AnimatedPage>
    );
}

export default Subscription;
