import { useNavigate } from "react-router-dom";
import AnimatedPage from "../../components/animatedPage";
import { useEffect, useState } from "react";

interface UserInfoProps {
  email: string;
  subscriptionStatus: "active" | "inactive";
  visible: boolean;
  onLogout: () => void;
  onClose: () => void;
}

export default function Component({ email, subscriptionStatus = "active", visible, onLogout, onClose}: UserInfoProps) {
    const navigate = useNavigate();

    const onClickInside = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const subscriptionButtonHandler = () => {
        if(subscriptionStatus === 'active') {
            navigate('/acc/sub/cancel');
        } else {
            navigate('/acc/sub');
        }
    }

  return (visible ? 
    <div className="fixed top-0 right-0">
      <div className="bg-white rounded-3xl p-8 w-96 shadow-lg" onClick={onClickInside}>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">User Info</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">Email</label>
            <p className="mt-1 text-lg text-gray-900">{email}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600">Subscription Status</label>
            <p className={`mt-1 text-lg font-semibold ${subscriptionStatus === "active" ? "text-blue-600" : "text-gray-500"}`}>
              {`${subscriptionStatus}  `} 
                <p 
                    className={`inline text-xs cursor-pointer align-middle ${subscriptionStatus === 'active' ? 'text-gray-500' : 'text-blue-600'}`}
                    onClick={subscriptionButtonHandler}
                >
                    ({subscriptionStatus === "active" ? "Cancel" : "Purchase"})
                </p>
            </p>
          </div>
        </div>
        <div className="mt-8">
          <button 
            onClick={onLogout}
            className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-colors"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  : null)
}