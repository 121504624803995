import { useEffect, useState } from "react";
import { User } from "../../@types/user";
import api from "../../utils/server";
import { useNavigate } from "react-router-dom";

const AccountHome = () => {
    const [account, setAccount] = useState<User | null>(null);
    const navigate = useNavigate();

    const onAccount = (data: any) => {
        console.log(data);
        setAccount(data);
    }

    const onError = (e: Response) => {
        console.log(e);
        navigate("/acc/login");
    };

    useEffect(() => {
        api('/user/me', {}, false, onAccount, onError);
    }, []);

    return (
        <div className="page-root center whitespace-break-spaces">
            {account ? JSON.stringify(account) : "Loading..."}
        </div>
    );
};

export default AccountHome;