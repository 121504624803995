import { createContext, useState } from "react";
import TVLoadingOverlay from "./loading";

interface OverlayContextType {
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

export const OverlayContext = createContext<OverlayContextType>({
    loading: false,
    setLoading: () => {},
});

const OverlayWrapper = ({children}: {children: React.ReactNode}) => {
    const [loading, setLoading] = useState(false);
    return (
        <OverlayContext.Provider value={{loading, setLoading}}>
            {loading && <TVLoadingOverlay/>} 
            {children}
        </OverlayContext.Provider>
    )
}

export default OverlayWrapper;