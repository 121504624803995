import React, { ReactNode, useEffect, useState } from "react";
import { Variants, motion } from "framer-motion";

const pageTransition = {
    type: "tween",
    ease: "easeIn",
    duration: 0.5,
};

const pageVariants = {
            init: {
                transition: {
                    duration: 0.5,
                },
                // opacity: 0,
                transform: "translateX(100%)",
            },
            in: {
                transition: {
                    duration: 0.5,
                },
                transform: "translateX(0%)",
                // opacity: 1,
            },
            out: {
                transition: {
                    duration: 0.5,
                },
                transform: "translateX(-100%)",
                // opacity: 0,
            },
};

const AnimatedPage = ({ children, className }: { children: ReactNode, className?: string }) => {
    return (
        <motion.div
            initial="init"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className={className || ""}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedPage;
