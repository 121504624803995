import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import OverlayWrapper from "./components/overlayContext";

const App = () => {
  return (
    <BrowserRouter>
      <OverlayWrapper>
        <Routing/>
      </OverlayWrapper>
    </BrowserRouter>
  );
};

export default App;